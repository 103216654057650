import React, { Component, useCallback } from "react";
import axios from 'axios';
import Alert from 'react-bootstrap/Alert';

// Import reCaptcha
import Recaptcha from 'react-recaptcha';

class ContactThree extends Component{
    constructor(props){
        super(props);
        this.state = {
          data: {
                Name: '',
                Email: '',
                Subject: '',
                Message: ''
          },
          errors: {
                inputCheckBoth: false,
                inputCheckDesign: false,
                inputCheckDev: false,
                isCaptchaValid: false,
                isErrorShown: false,
                isFormValid: false
          }
        

        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInput = this.handleInput.bind(this);
        this.onCaptchaVerify = this.onCaptchaVerify.bind(this);
    }
    
     // Handle visitor's interaction with inputs
    handleInput(event) {
      this.setState({
        [event.target.name]: event.target.value
      })  
      /*
      
      // Test for input and length of the value
      if (event.target.name !== 'Email') {
        this.setState({
          [event.target.name]: event.target.value
        })
      }

      // If input is for email address validate it with regexp
      if (event.target.name === 'Email') {
        // eslint-disable-next-line
        const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

        if (reg.test(String(event.target.value).toLowerCase())) {
          this.setState({
            [event.target.name]: event.target.value
          })
        }
      }
      */
    }

    // Show message in console when reCaptcha plugin is loaded
    onCaptchaLoad() {
      console.log('Captcha loaded')
    }

    // Update state after reCaptcha validates visitor
    async onCaptchaVerify(response) {
      
      console.log(response);
      
        await this.setState({
          isCaptchaValid: true
        })
        
      
    }
    

    handleSubmit(e){
        e.preventDefault();
        const { Name, Email } = this.state;
       // let captchaValid = (() => {this.state.errors.isCaptchaValid})
        console.log(() => {Boolean(this.state.errors.isCaptchaValidchaValid)});
        // Test
        if (Name.length > 0 && Email.length > 0 && this.state.errors.isCaptchaValid) {
          this.setState({
            isErrorShown: false,
            isFormValid: true
          })

        axios({
          method: "POST", 
          url:"https://2wjekpzwxj.execute-api.eu-west-1.amazonaws.com/01/contact-form", 
          data:  this.state
        }).then((response) => {
            // alert("Takk fyrir skilaboðin, við munum hafa samband eins fljótt og auðið er."); 
            // Reset state after sending the form
            this.setState({
              Name: '', 
              Email: '', 
              Subject: '', 
              Message: '', 
              inputCheckBoth: false,
              inputCheckDesign: false,
              inputCheckDev: false,
              isCaptchaValid: false,
              isErrorShown: false,
              isFormValid: false
            }) 
        })
        .catch((error) => {
            console.log(error);
        })

         
        
        
      } else {
        // Show error message
        this.setState({
          isErrorShown: true
        })
      }
    }


    render(){
      const { isFormValid, isErrorShown } = this.state;
        return(
            <div className="contact-form--1">
                <div className="container">
                    <div className="row row--35 align-items-start">
                        <div className="col-lg-6 order-2 order-lg-1">
                            <div className="section-title text-left mb--50">
                                <h2 className="title">{this.props.contactTitle}</h2>
                                <p className="description">Ég er laus í verkefni. Hafðu samband í síma: <a href="tel:+3548460999">846-0999</a>, email: 
                                    <a href="mailto:standardlausnir@standardlausnir.is"> standardlausnir@standardlausnir.is</a>, eða fylltu út formið hér að neðan. </p>
                            </div>
                            <div className="form-wrapper">
                                <form  id="contact-form" 
                                    onSubmit={this.handleSubmit.bind(this)} 
                                    method="POST">
                                    <label htmlFor="Name">
                                        <input
                                            type="text"
                                            name="Name"
                                            id="item01"
                                            value={this.state.Name}
                                            onChange={this.handleInput.bind(this)}
                                            placeholder="Nafn *"
                                            required={true}
                                        />
                                    </label>

                                    <label htmlFor="Email">
                                        <input
                                            type="text"
                                            name="Email"
                                            id="item02"
                                            value={this.state.Email}
                                            onChange={this.handleInput.bind(this)}
                                            placeholder="Email *"
                                            required={true}
                                        />
                                    </label>

                                    <label htmlFor="Subject">
                                        <input
                                            type="text"
                                            name="Subject"
                                            id="item03"
                                            value={this.state.Subject}
                                            onChange={this.handleInput.bind(this)}
                                            placeholder="Viðfangsefni"
                                        />
                                    </label>
                                    <label htmlFor="Message">
                                        <textarea
                                            type="text"
                                            id="item04"
                                            name="Message"
                                            value={this.state.Message}
                                            onChange={this.handleInput.bind(this)}
                                            placeholder="Skilaboð"
                                        />
                                    </label>
                        
                                      <Recaptcha
                                        onloadCallback={this.onCaptchaLoad.bind(this)}
                                        sitekey="6Lc0ANsZAAAAAD0m7iFSdqplgpIg2e4HcAepfZ2u"
                                        render="explicit"
                                        verifyCallback={this.onCaptchaVerify}
                                        theme="dark"
                                      />
                                    
                                    {isFormValid && <Alert variant="success" >Takk fyrir skilaboðin, við munum svara eins fljótt og auðið er! </Alert>}
                                    {isErrorShown && <Alert variant="danger" >Vinsamlegast fylltu út alla reitina. </Alert>}
                                    <button type="submit" className="rn-button-style--2 btn-solid" value="submit" name="submit" id="mc-embedded-subscribe">Senda fyrirspurn</button>
                                </form>
                            </div>
                        </div>
                        <div className="col-lg-6 order-1 order-lg-2">
                            <div className="thumbnail mb_md--30 mb_sm--30">
                                <img src={`${this.props.contactImages}`} alt="david-photo2"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}
    
export default ContactThree;